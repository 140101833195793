import React from "react";

/* REACT-BOOTSTRAP */
import { Button, Card } from "react-bootstrap";

/* REACT ROUTER */
import { Link } from "react-router-dom";

/* COMPONENTS */
import Rating from "./Rating";

function Product({ product }) {
  return (
    <Card className="my-3 rounded">
      
        <Card.Img src={product.image} />


      <Card.Body>
        
          <Card.Title as="div" style={{ display: "flex", justifyContent: "center" }}>
            <strong>{product.name}</strong>
          </Card.Title>
  

        <Card.Text as="div" style={{ display: "flex", justifyContent: "center" }}>
          <Rating
            value={product.rating}
            text={``}
            color={"#f8e825"}
          />
        </Card.Text>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <Card.Text as="h3">{product.price}DZ</Card.Text>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <Link className={"btn btn-outline-dark"} to={`/product/${product._id}`}>Details</Link>
        </div>
      </Card.Body>
      
    </Card>
  );
}

export default Product;
