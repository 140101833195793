import React from "react";
import logo from "../logo-w.png"
/* REACT-BOOTSTRAP */
import "./Footer.css"


function Footer() {
  return (
    <>
      <section class="contact-area" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="contact-content text-center">
                        <a href="#"><img src={logo} alt="logo"/></a>
                        <p>We are here to help you not just sell to you </p>
                        <div class="hr"></div>
                        <h6>N162 Mohamed Mahmoudi,Baraki, Alger.</h6>
                        <h6>0778123778</h6>
                        <div class="contact-social">
                            <ul>
                                <li><a class="hover-target" href=""><i class="fab fa-facebook-f"></i></a></li>
                                <li><a class="hover-target" href=""><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <p>Copyright &copy; 2024 <img src={logo} alt="logo"/> All Rights Reserved.</p>
    </footer>
    </>
  );
}

export default Footer;
